@import "../fonts";
@import "../variables";
@import "../responsive";

.dashboard {
    &-container {
        display: grid;
        grid-template-columns: 3fr 5fr;
        grid-column-gap: 24px;
        grid-auto-flow: column;
        width: 100%;

        @include responsive($max-width: $tablet-landscape-max-width) {
            display: flex;
            flex-direction: column;
        }

        .dashboard-column-one,
        .dashboard-column-two {
            grid-column: span 1;
            overflow: hidden;
        }
    }

    &__compose {
        position: relative;
        display: flex;
        flex-direction: column;
        border: none;
        overflow: hidden;

        &__button {
            width: 50%;
            margin-bottom: 32px;
            z-index: 2;
        }

        &__image {
            position: absolute;
            top: 100%;
            left: 100%;
            transform: translate(-75%, -90%);
            z-index: 1;

            @include mobile {
                display: none;
            }
        }

        &-text {
            @include font-rubik(16px, false, 400);
            display: flex;
            flex-direction: column;
            z-index: 2;
        }
    }

    &__dep-updates,
    &__promotion,
    &__weekly-updates {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 24px !important;
        background-color: #cfe1e8 !important;
        cursor: pointer;
        overflow: hidden;

        &__background {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(0, 0);
        }

        &__icon {
            position: absolute;
            top: 50%;
            left: 100%;
            transform: translate(calc(-100% - 24px), -50%);

            img {
                /** Filter value generated at https://codepen.io/sosuke/pen/Pjoqqp **/
                filter: invert(28%) sepia(9%) saturate(2948%) hue-rotate(159deg)
                    brightness(91%) contrast(86%);
            }
        }

        &__text {
            @include font-rubik(16px, var(--blue-darkerer), 500);
            z-index: 100;
        }
    }

    &__referrals {
        position: relative;
        border: none;
        overflow: hidden;

        &__body {
            @include font-rubik(20px, var(--midnight-100), 700);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 96px;

            &__button {
                z-index: 2;
            }

            &__text {
                margin-bottom: 16px;
                text-align: center;
                z-index: 2;
            }
        }

        &__image {
            position: absolute;
            top: 100%;

            &--left {
                left: 0;
                transform: translate(-2px, calc(-100% + 2px));
                z-index: 1;
            }

            &--right {
                left: 100%;
                transform: translate(calc(-100% + 2px), calc(-100% + 2px));
                z-index: 1;
            }

            @include mobile {
                display: none;
            }
        }
    }

    &__dep-updates,
    &__weekly-updates {
        /** See promotions block for more styles **/
        justify-content: flex-start;

        &__background {
            // top: 50%;
            // left: 100%;
            width: auto;
            height: 100%;
            transform: translate(52%, 0);

            img {
                width: auto;
                height: 100% !important;
            }
        }

        &__link {
            &:hover {
                text-decoration: none;
                span {
                    text-decoration: none !important;
                }
            }
        }
    }

    &__weekly-updates {
        background-color: var(--orange-lightest) !important;

        &__background {
            top: 50%;
            left: 100%;
            width: auto;
            height: 100%;
            transform: translate(-100%, -50%);

            img {
                width: auto;
                height: 100% !important;
            }
        }

        &__icon {
            img {
                /** Filter value generated at https://codepen.io/sosuke/pen/Pjoqqp **/
                filter: invert(20%) sepia(63%) saturate(3395%) hue-rotate(4deg)
                    brightness(98%) contrast(90%);
            }
        }

        &__text {
            color: var(--orange-dark);
        }
    }
}
