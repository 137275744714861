.dashboardNews {
    .body {
        display: flex;
        width: 100%;
        opacity: 0;
        transition: opacity 0.25s ease-out;

        &--active {
            opacity: 1;
        }
    }

    .carousel {
        max-width: 100%;
        width: 100%;

        .item {
            width: 40%;
            max-width: 40%;
        }
    }

    .placeholder {
        width: 100%;
        max-width: 100%;
        background-color: var(--gray-dark-background);
        border-radius: 8px;
        height: 100%;
        pointer-events: none;
    }
}
