@import "../../../../css/variables";

.dashboardTiles {
    display: grid;
    grid-gap: 24px;
    width: 100%;
    margin-bottom: 24px;

    @include tablet {
        grid-template-columns: 1fr !important;
    }
}
