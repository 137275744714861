@import "../../../../../../css/fonts";

.dashboardTile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    border-radius: 8px;
    background-color: var(--dust-100);
    text-align: center;
    cursor: pointer;
    height: 100%;

    .content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-height: 76px;

        .subtitle {
            @include font-rubik(14px, var(--midnight-100), 400);
            min-height: 40px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .title {
            @include font-rubik(18px, var(--midnight-100), 700);
            min-height: 28px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: var(--pacific-blue-20);
        margin-bottom: 16px;

        svg {
            width: 32px;
            height: 32px;
            color: var(--dust-100);
        }
    }
}
