@import "../../../../../css/fonts";

.features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    padding: 2rem;

    .feature {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: var(--dust-20);
        padding: 1rem;
        border-radius: 0.5rem;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            margin-bottom: 0.25rem;
            border-radius: 50%;
            color: var(--white);
        }

        .subtitle {
            @include font-rubik(12px, var(--midnight-80), 400);
        }

        .title {
            @include font-rubik(14px, var(--midnight-100), 500);
        }
    }
}
