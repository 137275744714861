@import "../../../../../../../css/fonts";

.communityModalActionsSupportedBases {
    display: flex;
    justify-content: center;
    width: 100%;

    .button {
        display: flex;
        align-items: center;
        gap: 4px;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;

        .text {
            @include font-rubik(12px, var(--midnight-60), 400);
        }

        &:hover {
            .infoIcon {
                opacity: 0.8;
            }

            .supportedBasesText {
                text-decoration: underline;
            }
        }
    }
}

.basesPopover {
    max-width: 320px;

    :global(.popover-header) {
        background-color: var(--blue-darkerer);
        color: var(--white);
    }

    :global(.popover-body) {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .branchName {
            margin-bottom: 4px;
        }

        .list {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li:not(:last-child) {
                margin-bottom: 4px;
            }
        }
    }
}
