@import "../../css/fonts";

.button {
    @include font-rubik(16px, false, 600);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    padding: 8px 24px;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s ease-out;
    text-decoration: none !important;

    span {
        position: relative;
        top: 1px;
    }
}

.buttonPrimary {
    @include font-rubik(false, var(--white), false);
    gap: 0.5rem;
    border: 2px solid var(--blue-darkerer);
    background-color: var(--blue-darkerer);
    color: var(--white);

    &:hover {
        background-color: var(--blue-dark);
        border-color: var(--blue-dark);
    }

    &[disabled],
    &.disabled {
        color: var(--gray-darkerer);
        background-color: var(--gray-dark);
        border-color: var(--gray-dark);
        pointer-events: none;
    }

    &--loading {
        pointer-events: none;

        // TODO: Loading and disabled should be made into two different states
        &[disabled] {
            border: 2px solid var(--blue-darkerer);
            background-color: var(--blue-darkerer);
            color: var(--white);
        }
    }

    a {
        text-decoration: none !important;
        color: white;
    }

    &-warning {
        background-color: var(--error-100);
        border-color: var(--error-100);

        &:hover {
            background-color: var(--error-40);
            border-color: var(--error-40);
        }
    }
}

.buttonSecondary {
    gap: 0.5rem;
    border: 2px solid var(--blue-darkerer);
    background-color: transparent;
    color: var(--blue-darkerer);

    &[disabled],
    &.disabled {
        border: 2px solid var(--gray-light) !important;
        color: var(--gray-light) !important;
        cursor: not-allowed !important;
    }

    &--filled {
        background-color: var(--blue-darkerer);
        color: var(--white);
    }

    &--gradient {
        border: 3px solid #0000 !important;
        background: linear-gradient(var(--dust-100), var(--dust-100))
                padding-box,
            linear-gradient(
                    var(--angle),
                    var(--mission-orange-100),
                    var(--mission-orange-40)
                )
                border-box;
        animation: rotate 4s ease infinite;
    }

    &--loading {
        &[disabled] {
            border: 2px solid var(--blue-darkerer) !important;
            background-color: transparent !important;
            color: var(--blue-darkerer) !important;
        }
    }
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

.buttonSignIn {
    @include font-rubik(16px, var(--midnight-100), 500);
    justify-content: flex-start;
    height: 64px;
    border: none;
    padding: 16px 32px;
    background-color: var(--white);
    text-align: left;
    text-transform: none;
    box-shadow: 0px 0px 1px rgb(0 0 0 / 8%), 0px 1px 1px rgb(0 0 0 / 17%);

    &[disabled] {
        border: 2px solid var(--gray-light) !important;
        color: var(--gray-light) !important;
        cursor: not-allowed !important;
    }

    .icon {
        display: inline-block;
        margin-right: 32px;
    }
}
