@import "../../../../../../css/fonts";

.dashboardLettersCredits {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid var(--steel-blue-80);
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: var(--steel-blue-20);
    cursor: pointer;

    .icon {
        width: 20px !important;
        height: 20px !important;
    }

    .label {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.375rem;
        font-size: 1rem;
        color: var(--blue-darkerer);

        svg {
            width: 1rem;
            height: 1rem;
        }
    }

    .value {
        @include font-rubik(1.25rem, var(--blue-darkerer), false);
        margin: 0;
    }
}
