@import "../../../../../css/fonts";

.communityModalActions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 0 2rem 2rem;
    text-align: center;

    .button {
        width: 100%;
        max-width: 20rem;
        border-radius: 32px;
    }

    .header {
        .text {
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
        }
    }

    .helper {
        max-width: 20rem;

        .text {
            @include font-rubik(14px, var(--midnight-60), 400);

            .link {
                border: none;
                background: none;
                padding: 0;
                font-weight: 500;
                text-decoration: underline !important;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .selectContainer {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 20rem;

        .select {
            width: 100%;
            background-color: var(--white);
            cursor: pointer;

            &:disabled {
                background-color: var(--gray-lighter);
                cursor: not-allowed;
            }
        }

        .spinner {
            position: absolute;
            right: -40px;
            top: 50%;
            transform: translateY(calc(-50% + 0.25rem));
        }
    }
}
