@import "../../../../css/fonts";
@import "../../../../css/variables";

.dashboardReferrals {
    position: relative;
    border: none;
    overflow: hidden;
    background: var(--dust-100);
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 24px;

    .body {
        @include font-rubik(20px, var(--midnight-100), 700);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 96px;

        .button {
            z-index: 200 !important;
        }

        .text {
            margin-bottom: 16px;
            text-align: center;
            z-index: 2;
        }
    }

    .imageLeft,
    .imageRight {
        position: absolute;
        top: 100%;
        z-index: 1 !important;
    }

    .imageLeft {
        left: 0;
        transform: translate(-2px, calc(-100% + 2px));
    }

    .imageRight {
        left: 100%;
        transform: translate(calc(-100% + 2px), calc(-100% + 2px));
    }

    @include mobile {
        .imageLeft,
        .imageRight {
            display: none;
        }
    }
}
