.previewCard {
    border-radius: 8px;
    background-color: var(--dust-100);
    cursor: pointer;

    .image {
        display: flex;
        aspect-ratio: 2 / 1;
        background-size: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .text {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 16px;

        .body,
        .header {
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: wrap;
            word-wrap: break-word;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .body {
            height: 48px;
        }

        .header {
            font-size: 20px;
            margin-bottom: -4px;
        }

        .cta {
            font-weight: 600;
            color: var(--pacific-blue-100);
        }
    }
}
