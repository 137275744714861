@import "../../../../css/variables";

.dashboardMessages {
    @include hideScrollBar;
    display: flex;
    width: 100%;
    gap: 24px;
    margin-bottom: 24px;
    overflow-x: hidden;

    .carousel {
        max-width: 100%;
        width: 100%;

        .item {
            width: 40%;
            max-width: 40%;
        }
    }

    .placeholder {
        width: 100%;
        max-width: 100%;
        background-color: var(--gray-dark-background);
        border-radius: 8px;
        height: 100%;
        pointer-events: none;
    }
}
