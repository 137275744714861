@import "../../../css/fonts";

.communityModal {
    margin: 0 auto;

    .body {
        padding: 0;
    }

    .content {
        padding: 0;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        padding: 2rem;
        background-color: var(--blue-darkerer);
        color: var(--white);
        text-align: center;

        .subtitle {
            @include font-rubik(18px, var(--white), 400);
            opacity: 0.9;
        }
        .title {
            @include font-rubik(28px, var(--white), 600);
        }
    }
}
